


import Component from 'vue-class-component';
import {Prop, Vue} from 'vue-property-decorator';
import {mapGetters} from 'vuex';
import {TabNames} from '@/_modules/promo/types/side-bar-right-store-state.type';
// @ts-ignore
import QrcodeVue from 'qrcode.vue';
// @ts-ignore
import biletumSharer from '@/views/components/popups/biletumSharer.vue';
import IconCloseQrPopup from '@/_modules/icons/components/qrpopup/icon-close-qr-popup.vue';
import IconDownloadQrPopup from '@/_modules/icons/components/qrpopup/icon-download-qr-popup.vue';
import IconShareQrPopup from '@/_modules/icons/components/qrpopup/icon-share-qr-popup.vue';

@Component({
  name: 'qr-popup',
  components: {
    QrcodeVue,
    biletumSharer,
    IconCloseQrPopup,
    IconDownloadQrPopup,
    IconShareQrPopup,
  },
  computed: {
    ...mapGetters('sideBarRightStore', {
      isSideBarRightOpen: 'isOpen',
      activeTabName: 'activeTabName',
    }),
  },
})
export default class QrPopup extends Vue {

  public isSideBarRightOpen: boolean;
  public activeTabName: TabNames;
  public openingAnimationInitialized: boolean = false;
  public openingAnimTimeoutId: number = -1;

  @Prop({type: String, default: ''})
  public readonly stringToEncode: string;

  @Prop({type: String, default: ''})
  public readonly popupTitle: string;

  @Prop({type: String, default: ''})
  public readonly popupDescription: string;

  public mounted(): void {
    this.initOpeningAnimation();
  }

  public beforeDestroy(): void {
    this.killOpeningAnimation();
  }

  private emitClose(): void {
    this.$emit('closeQRPopup');
  }

  private initOpeningAnimation(): void {
    this.openingAnimTimeoutId = window.setTimeout(() => {
      this.openingAnimationInitialized = true;
    }, 150);
  }

  private killOpeningAnimation(): void {
    if (this.openingAnimTimeoutId > -1) {
      window.clearTimeout(this.openingAnimTimeoutId);
    }
  }

  // @ts-ignore
  private toggleSharer(): void {
    const refName = 'sideBarRightQrBiletumSharer';
    // @ts-ignore
    if (!this.$refs[refName]) {
      return;
    }
    // @ts-ignore
    this.$refs[refName].toggleSharer();
  }

  /* Trigger downloading of a PNG image from canvas to a file.
   * Filename is created from strToEncode, PNG is default in Canvas.toDataURL
   * Creates a temporary HTMLAnchorElement in <body>, clicks it and removes it
   * TODO: refactor if possible?
   */
  private downloadQrAsFile(): void {
    if (!this.$refs.qrCanvasWrap) {
      return;
    }
    const qrCanvasWrap: Element = (((this.$refs.qrCanvasWrap as Vue).$el) as Element);
    if (!qrCanvasWrap) {
      return;
    }
    const qrCanvas: HTMLCanvasElement = (qrCanvasWrap as HTMLDivElement).getElementsByTagName('canvas')[0];
    if (!qrCanvas) {
      return;
    }
    const filename: string = this.stringToEncode.replace('https://', '').replace(/[/.]+/g, '_');
    const tempAnchor: HTMLAnchorElement = document.createElement('a');
    tempAnchor.href = `${process.env.VUE_APP_API_BASE_URL}/qr?text=${this.stringToEncode}`;
    tempAnchor.setAttribute('download', filename + '.png');
    tempAnchor.setAttribute('style', 'position: absolute; top: -100px; left: -100px; z-index: 2;');
    tempAnchor.innerHTML = 'qr code to download';
    document.getElementsByTagName('body')[0].appendChild(tempAnchor);
    tempAnchor.click();
    this.$nextTick(() => {
      tempAnchor.parentNode.removeChild(tempAnchor);
    });
  }
}
