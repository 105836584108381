


import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { TNotification } from '@/_modules/promo/types/notification.type';
import PromoNotification from '@/_modules/promo/components/promo-notification/promo-notification.vue';

@Component({
  components: {
    PromoNotification,
  },
  computed: {
    ...mapGetters({
      notifications: 'notificationsStore/notifications',
    }),
  },
})
export default class SideBarRightTabNotifications extends Vue {

  public readonly notifications: TNotification[];

}
