<template>
  <svg width="21" height="15" viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.757935 8.34034L5.20737 12.7174L14.8658 1.03317" stroke="black" stroke-width="1.5" stroke-linecap="round"
          stroke-linejoin="round"/>
    <path d="M8.75242 11.8492L9.62062 12.7174L19.2791 1.03317" stroke="black" stroke-width="1.5" stroke-linecap="round"
          stroke-linejoin="round"/>
  </svg>
</template>
<script>
import Vue from 'vue';

const IconAllRead = Vue.extend({
  name: 'icon-all-read',
});
export default IconAllRead;
</script>
