


import Component, {mixins} from 'vue-class-component';
import {mapGetters} from 'vuex';
import {TContact} from '@/_types/contact.type';
import {TUser} from '@/_types/user.type';
import {TEvent} from '@/_types/event.type';
import {TPromoPage} from '@/_types/promo-page/promo-page.type';
import {TabNames} from '@/_modules/promo/types/side-bar-right-store-state.type.ts';
import Avatar from '@/_components/avatar/avatar.vue';
import sideBarRightTabContact
  from '@/_modules/promo/components/side-bar-right-tab-contact/side-bar-right-tab-contact.vue';
import sideBarRightTabCorrespondence
  from '@/_modules/promo/components/side-bar-right-tab-correspondence/side-bar-right-tab-correspondence.vue';
import IconArrowLeft from '@/_modules/icons/components/icon-arrow-left.vue';
import IconCorrespondence from '@/_modules/icons/components/sidebar/icon-correspondence.vue';
import IconSchedule from '@/_modules/icons/components/sidebar/icon-schedule.vue';
import IconNotifications from '@/_modules/icons/components/sidebar/icon-notifications.vue';
import SideBarRightTabNotifications
  from '@/_modules/promo/components/side-bar-right-tab-notifications/side-bar-right-tab-notifications.vue';
import SideBarRightTabSchedule
  from '@/_modules/promo/components/side-bar-right-tab-schedule/side-bar-right-tab-schedule.vue';
import NotificationsMixin from '@/_mixins/notifications.mixin.ts';

@Component({
  components: {
    Avatar,
    sideBarRightTabContact,
    sideBarRightTabCorrespondence,
    IconArrowLeft,
    IconCorrespondence,
    IconSchedule,
    IconNotifications,
    SideBarRightTabNotifications,
    SideBarRightTabSchedule,
  },
  computed: {
    ...mapGetters({
      userInfo: '_userStore/user',
      event: '_eventStore/event',
      promoPage: 'promoPageStore/promoPage',
      contactInfo: 'promoPageStore/contact',
      isAuthenticated: 'authStore/isAuthenticated',
      messagesCount: 'notificationsStore/messagesCount',
      noticedMessagesCount: 'notificationsStore/noticedMessagesCount',
      isOpen: 'sideBarRightStore/isOpen',
      activeTabName: 'sideBarRightStore/activeTabName',
      isUserSettingsOpen: 'sideBarRightStore/isUserSettingsOpen',
    }),
  },
})
export default class PromoSideBarRight extends mixins(NotificationsMixin) {

  public TabNames: typeof TabNames = TabNames;

  public readonly isOpen: boolean;
  public readonly activeTabName: TabNames;
  public readonly userInfo: TUser;
  public readonly event: TEvent;
  public readonly promoPage: TPromoPage;
  public readonly contactInfo: TContact;
  public readonly isAuthenticated: boolean;
  public readonly meetingsCount: number;
  public readonly messagesCount: number;
  public readonly isUserSettingsOpen: boolean;

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  public get contactInitials(): string {
    const defaultInitialsText = '';
    if (!this.contactInfo) {
      return defaultInitialsText;
    } else if (!this.contactInfo.name || !this.contactInfo.surname) {
      return defaultInitialsText;
    }
    return this.contactInfo.name.substring(0, 1).toUpperCase() + this.contactInfo.surname.substring(0, 1).toUpperCase();
  }

  public created(): void {
    this.$store.dispatch('sideBarRightStore/setComponentPresence', true);
  }

  public mounted(): void {
    this.$store.dispatch('sideBarRightStore/setComponentPresence', true);
  }

  public beforeDestroy(): void {
    this.$store.dispatch('sideBarRightStore/setComponentPresence', false);
  }

  private unfoldTheSideBar(): void {
    this.$store.dispatch('sideBarRightStore/open');
  }

  private foldTheSideBar(): void {
    this.$store.dispatch('sideBarRightStore/close');
    this.$store.dispatch('sideBarRightStore/clearActiveTab');
  }

  private toggleTheSideBar(): void {
    this.$store.dispatch('sideBarRightStore/toggle');
  }

  private onMenuItemClick(tabName: TabNames): void {
    if (!this.isOpen) {
      this.unfoldTheSideBar();
    } else if ((tabName === TabNames.CONTACT) && this.isUserSettingsOpen) {
      this.$store.dispatch('sideBarRightStore/setIsUserSettingsOpen', false);
    } else if (tabName === this.activeTabName) {
      this.toggleTheSideBar();
      tabName = TabNames.NONE;
    }
    this.$store.dispatch('sideBarRightStore/setActiveTab', tabName);
  }

}
