<template>
  <div style="padding-top: 90px;">
    <promo-side-bar-left/>
    <promo-side-bar-right/>
    <div class="nav-bar">
      <div class="biletum-tabs biletum-tabs-promopage biletum-tabs-promopage-listsource">
        <span class="biletum-tab"
              @click="setTab(0)"
              :class="{ 'biletum-tab-current': !tab }">{{ $t("navigation.all") }}</span>
        <span class="biletum-tab"
              @click="setTab(1)"
              :class="{ 'biletum-tab-current': tab === 1 }">{{ $t("navigation.my") }}</span>
      </div>
      <div class="create-btn-block">
        <button @click="create = true"
                :title="$t('button.create_new')"
                class="control control-button-secondary create-btn">{{ $t("button.create_new") }}
        </button>
      </div>
    </div>
    <div class="main main-skeleton" v-if="!notes.List">
      <mu-circular-progress
        class="demo-circular-progress"
        :size="36"></mu-circular-progress>
    </div>
    <div class="main" v-if="contact && notes.List">
      <perfect-scrollbar @ps-y-reach-end="loadMore"
                         class="notes-list-scroller scroll-height"
                         id="container"
      >
        <div class="wrap">
          <div class="card" v-if="create">
            <div class="card_head">
              <div class="avatar">
                <img :src="setAvatar(contact.photo_url)" alt="avatar"/>
              </div>
              <div class="title_block">
                <div class="title">{{ contact.name }} {{ contact.surname }}</div>
              </div>
            </div>
            <div class="card_body">
              <div class="body_title">
                <div class="control">
                  <mu-text-field v-model="createFormData.title"
                                 :placeholder="$t('title.write_your_note_title')"
                                 :rows="1"
                                 :rows-max="1"></mu-text-field>
                  <div class="error-messages" v-if="$v.createFormData.title.$dirty && $v.createFormData.title.$invalid">
                    <div v-if="!$v.createFormData.title.required" class="error-message">
                      {{ $t('errors.validation.required') }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="body_text">
                <div class="control">
                <mu-text-field v-model="createFormData.text"
                               :placeholder="$t('title.write_your_note_text')"
                               multi-line
                               :rows="3"
                               :rows-max="3"
                               :max-length="2000"
                ></mu-text-field>
                  <div class="error-messages" v-if="$v.createFormData.text.$dirty && $v.createFormData.text.$invalid">
                    <div v-if="!$v.createFormData.text.required" class="error-message">
                      {{ $t('errors.validation.required') }}
                    </div>
                    <div v-if="!$v.createFormData.text.maxLength" class="error-message">
                      {{ $t('errors.validation.directMessageIsTooLong') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card_footer">
              <div class="switch-container">
                <div class="flex-container">
                  <switchCheckbox
                    @switch-checkbox="setNoteIsPrivate"
                    :title="createFormData && createFormData.is_private ? '' : $t('title.public_note')"
                  />
                </div>
                <div class="hint-block" v-if="createFormData.is_private">{{ $t('title.note_is_private_hint') }}</div>
                <div class="hint-block" v-if="!createFormData.is_private">{{ $t('title.note_is_public_hint') }}</div>
              </div>
              <div class="btn-group">
              </div>
              <div class="btn-group">
            <span class="btn save"
                  :title="$t('button.create_new')"
                  @click="createNote()">{{ $t("button.save") }}</span>
                <span class="btn delete"
                      :title="$t('button.delete')"
                      @click="create = false">{{ $t("button.cancel") }}</span>
              </div>
            </div>
            <div class="inner-circular-progress" v-if="isSendLoading">
              <mu-circular-progress :size="36"></mu-circular-progress>
            </div>
          </div>
          <div class="card" v-for="(note, index) in notes.List" :key="index">
            <div class="card_head" @click="toContact(note.owner.id)"
                 :class="{'card_head_my' : note.owner.id === contact.id}">
              <div class="avatar">
                <img :src="setAvatar(note.owner.photo_url)" alt="avatar"/>
              </div>
              <div class="title_block">
                <div class="title" v-if="note.owner.name || note.owner.surname">{{ note.owner.name }}
                  {{ note.owner.surname }}
                </div>
                <div class="title" v-else>{{ $t('contacts.info.noname') }}</div>
                <div class="subtitle">
                  {{ transformDate(note.updated_at) }}
                  {{ transformTime(note.updated_at) }}
                </div>
              </div>
            </div>
            <div class="card_body">
              <div v-if="edit && noteId === note.id">
                <div class="body_title">
                  <div class="control">
                    <mu-text-field v-model="formData.title"
                                   :placeholder="$t('title.write_your_note_title')"
                                   :rows="1"
                                   :rows-max="1"></mu-text-field>
                    <div class="error-messages" v-if="$v.formData.title.$dirty && $v.formData.title.$invalid">
                      <div v-if="!$v.formData.title.required" class="error-message">
                        {{ $t('errors.validation.required') }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="body_text">
                  <div class="control">
                  <mu-text-field v-model="formData.text"
                                 :placeholder="$t('title.write_your_note_text')"
                                 multi-line
                                 :rows="3"
                                 :rows-max="3"
                                 :max-length="2000"></mu-text-field>
                    <div class="error-messages" v-if="$v.formData.text.$dirty && $v.formData.text.$invalid">
                      <div v-if="!$v.formData.text.required" class="error-message">
                        {{ $t('errors.validation.required') }}
                      </div>
                      <div v-if="!$v.formData.text.maxLength" class="error-message">
                        {{ $t('errors.validation.directMessageIsTooLong') }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="body_title">
                  {{ getTruncatedText(note.title, 42) }}
                </div>
                <perfect-scrollbar class="truncate-scroll-height">
                  <div
                    class="body_text"
                    :class="{'collapsed': (isCollapsed && toggledId) !== note.id}" >
                    <span v-html="linkifyUrls(getTruncatedText(note.text, ((isCollapsed && toggledId === note.id) ? 9999 : 210)))" />
                    <button
                      class="collapse-action"
                      v-if="note.text.length > 210"
                      @click="onToggle(note.id)"
                    >
                      {{ (isCollapsed && toggledId === note.id) ? $t('button.less') : $t('button.more') }}
                    </button>
                  </div>
                </perfect-scrollbar>
              </div>
            </div>
            <div class="card_footer">
              <div class="switch-container" v-if="edit && noteId === note.id">
                <div class="flex-container">
                  <switchCheckbox
                    @switch-checkbox="formData.is_private = !$event"
                    :checked="!formData.is_private"
                    :title="formData && formData.is_private ? '' : $t('title.public_note')"
                  />
                </div>
                <div class="hint-block" v-if="formData.is_private">{{ $t('title.note_is_private_hint') }}</div>
                <div class="hint-block" v-if="!formData.is_private && edit">{{ $t('title.note_is_public_hint') }}</div>
              </div>
              <div class="btn-group" v-if="!edit">
            <span @click.stop="share($event, note.id)"
                  :title="$t('button.share')"
                  @mouseover="setShareNoteId(note.id)"
                  @mouseleave="removeShareNoteId()"
                  class="cur-p btn-share-wrap"
            >
              <iconShare class="btn btn-share"
                         :color="noteId === note.id && !onChangeLoading ? '#57C7FF' : '#707070'"
              ></iconShare>
            </span>
                <span @click="removeFav(note.id)"
                      :title="$t('button.rm_favorites')"
                      v-if="note.is_favorite">
              <iconStar class="btn"
                        :active="note.is_favorite"
                        :color="'#00B6F8'"/>
            </span>
                <span v-else
                      :title="$t('button.favorites')"
                      @click="addFav(note.id)">
              <iconStar class="btn"
                        :active="note.is_favorite"
                        :color="'#707070'"/>
            </span>
              </div>
              <div class="btn-group">
            <span class="btn save"
                  v-if="edit && noteId === note.id  && note.is_creator"
                  :title="$t('button.save')"
                  @click="editNote()">{{
                $t("button.save")
              }}</span>
                <span class="btn save"
                      :title="$t('button.edit')"
                      v-else-if="note.is_creator"
                      @click="mode(false, true, note.id) ">{{
                    $t("button.edit")
                  }}</span>
                <span class="btn delete"
                      :title="$t('button.delete')"
                      @click="remove(note.id)"
                      v-if="note.is_creator">{{ $t("button.delete") }}</span>
              </div>
            </div>
            <div class="inner-circular-progress" v-if="onChangeLoading && noteId === note.id">
              <mu-circular-progress :size="36"></mu-circular-progress>
            </div>
          </div>
        </div>

        <div class="loading-block" v-if="isLoading">
          <mu-circular-progress
            :size="36"></mu-circular-progress>
        </div>
      </perfect-scrollbar>
      <biletumSharer :url="shareLink"
                     :position="sharer.position"
                     ref="biletumSharer"></biletumSharer>
      <div class="push"></div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import NoAvatarImageUser from '@/assets/images/no-avatar-60x60.svg';
import iconShare from '@/_modules/icons/components/icon-share.vue';
import iconStar from '@/_modules/icons/components/icon-star.vue';
import biletumSharer from './components/popups/biletumSharer'
import { DateTimeHelper } from '@/helpers/date-time.helper';
import switchCheckbox from './components/parts/switch-checkbox';

import { NotesStore } from '@/store/mudules/notesStore';
import PromoSideBarLeft from '@/_modules/promo/components/side-bar-left/side-bar-left.vue';
import PromoSideBarRight from '@/_modules/promo/components/side-bar-right/side-bar-right.vue';

import { maxLength, required } from 'vuelidate/lib/validators';
import ChatHelper from '../_modules/chat/helpers/chat.helper';

const NOTES_STORE_ALL = 'notesStore/all';
const NOTES_STORE_MY = 'notesStore/my';

export default {
  name: 'note-list',
  components: { PromoSideBarLeft, PromoSideBarRight, iconShare, iconStar, biletumSharer, switchCheckbox },
  computed: {
    ...mapState({
      notesLoadingMy: state => state[NOTES_STORE_MY] && state[NOTES_STORE_MY].notesLoading,
      notesLoading: state => state[NOTES_STORE_ALL] && state[NOTES_STORE_ALL].notesLoading,
      notesAll: state => state[NOTES_STORE_ALL] && state[NOTES_STORE_ALL].notes,
      notesMy: state => state[NOTES_STORE_MY] && state[NOTES_STORE_MY].notes,
      eventId: state => state.noteStore.id,
      listTotal: state => state.noteStore.noteList.Total,
    }),
    ...mapGetters({
      userInfo: '_userStore/user',
      contact: 'promoPageStore/contact',
    }),
    notes() {
      if (this.type === 'own') {
        return this.notesMy;
      } else if (this.type === 'all') {
        return this.notesAll;
      } else return []
    },
    isLoading() {
      return this.notesLoadingMy || this.notesLoading
    },
    onSwitchChanged() {
      if (this.createFormData.is_private) {
        return this.$t('title.public_note')
      } else {
        return ''
      }
    }
  },
  watch: {
    edit: {
      deep: true,
      handler() {
        if (this.edit) {
          this.setData();
        }
      }
    },
    userInfo: {
      deep: true,
      handler() {
        if (!this.userInfo || !this.userInfo.id || this.notesMy) {
          return;
        }
        this.user_id = this.userInfo.id;
        this.$store.registerModule(NOTES_STORE_MY, new NotesStore({
          event_id: this.$route.params.eventId,
          user_id: this.user_id
        }));
      }
    },
  },
  data() {
    return {
      toggledId: null,
      isCollapsed: true,
      create: false,
      edit: false,
      noteId: null,
      shareLink: '',
      shareColor: '#707070',
      isActive: false,
      sharer: {
        previous_id: null,
        url: window.location.protocol + '//' + window.location.host + window.location.pathname + window.location.search + window.location.hash,
        position: {
          t: 0,
          l: 0
        },
        sharingTexts: {
          text: '',
          title: ''
        }
      },
      query: {
        offset: 0,
        limit: 10,
      },
      type: 'all',
      totalPages: 0,
      tab: 0,
      formData: {
        note_id: null,
        title: '',
        text: '',
        is_private: true,
      },
      createFormData: {
        event_id: null,
        title: '',
        text: '',
        is_private: true,
      },
      user_id: null,
      isSendLoading: false,
      onChangeLoading: false,
    }
  },
  validations: {
    formData: {
      title: {
        required,
      },
      text: {
        required,
        maxLength: maxLength(2000)
      },
    },
    createFormData: {
      title: {
        required,
      },
      text: {
        required,
        maxLength: maxLength(2000)
      },
    },
  },

  destroyed() {
    this.$store.unregisterModule(NOTES_STORE_ALL);
    this.$store.unregisterModule(NOTES_STORE_MY);
  },
  created() {
    this.$store.dispatch('_userStore/getUser'); // TODO: remove this line? It is already called in App.vue
    this.$store.registerModule(NOTES_STORE_ALL, new NotesStore({
      event_id: this.$route.params.eventId,
    }));

    this.$store.dispatch('promoPageStore/getContact', Number(this.$route.params.eventId));
    this.callNoteList();
    this.setTab(0);
    this.createFormData.event_id = this.$route.params.eventId;
  },
  methods: {
    setNoteIsPrivate($event) {
      this.createFormData.is_private = !$event;
    },
    async callNoteList() {
      if (this.type === 'own') {
        await this.$store.dispatch(NOTES_STORE_MY + '/getListMy', {
          ...this.query
        });
      } else if (this.type === 'all') {
        await this.$store.dispatch(NOTES_STORE_ALL + '/getList', {
          ...this.query
        });
      }

      this.mode(false, false, false);
    },
    mode(create, edit, noteId) {
      this.create = create;
      this.edit = edit;
      this.noteId = noteId;
    },
    setAvatar(url) {
      return url ? url : NoAvatarImageUser;
    },
    async addFav(id) {
      await this.$store.dispatch('noteStore/addFav', id);
      this.callNoteList();
    },
    async removeFav(id) {
      this.$store.dispatch('noteStore/removeFav', id);
      await this.callNoteList();
    },
    async remove(id) {
      this.noteId = id;
      this.onChangeLoading = true;
      await this.$store.dispatch('noteStore/removeNote', id);
      await this.callNoteList();
      this.onChangeLoading = false;
    },
    transformDate(val) {
      return this.$moment(this.$moment(val)).format('DD MMM YYYY');
    },
    transformTime(val) {
      return this.$moment(this.$moment(val)).format('HH:mm');
    },
    setTab(val) {
      this.tab = Number(val);
      if (this.tab === 0 || !this.tab) {
        this.type = 'all';
        this.query.offset = 0;
        this.query.limit = 10;
        this.callNoteList();
      }
      if (this.tab === 1) {
        this.type = 'own';
        this.query.offset = 0;
        this.query.limit = 10;
        this.callNoteList();
      }
    },
    getTruncatedText(text, length) {
      if (!text || !length || length <= 0 || text.length <= length) {
        return text;
      }
      return `${text.substring(0, length)}...`
    },
    linkifyUrls(inputString) {
      return ChatHelper.createLinks(inputString);
    },
    async editNote() {
      this.$v.formData.$touch();

      if (this.$v.formData.$pending || this.$v.formData.$invalid) {
        return;
      }

      this.onChangeLoading = true;
      await this.$store.dispatch('noteStore/editNote', this.formData, this.noteId);
      await this.callNoteList();
      this.edit = false;
      this.onChangeLoading = false;
    },
    setData() {
      let note = this.notes.List.filter((item) => {
        if (item.id === this.noteId) {
          return { item };
        }
      });
      this.formData.note_id = note[0].id;
      this.formData.title = note[0].title;
      this.formData.text = note[0].text;
      this.formData.is_private = note[0].is_private;
    },
    async createNote() {
      this.$v.createFormData.$touch();

      if (this.$v.createFormData.$pending || this.$v.createFormData.$invalid) {
        return;
      }

      this.isSendLoading = true;
      await this.$store.dispatch('noteStore/addNote', this.createFormData);
      await this.callNoteList();
      this.isSendLoading = false;
      this.createFormData.title = '';
      this.createFormData.text = '';
      this.createFormData.is_private = false;
      this.create = false;
    },
    setShareNoteId(id) {
      this.noteId = id;
    },
    removeShareNoteId() {
      if (!this.edit) {
        this.noteId = null;
      }
    },
    share(event, id) {
      let clickedElementRect = event.target.getBoundingClientRect();
      this.sharer.position.t = window.scrollY + clickedElementRect.y;
      this.sharer.position.l = window.scrollX + clickedElementRect.x + 100;
      this.shareLink = window.location.protocol + '//' + window.location.host + this.$route.path;

      if (!this.sharer.previous_id || (this.sharer.previous_id === id)) {
        this.$refs.biletumSharer.toggleSharer();
      } else {
        this.$refs.biletumSharer.showSharer();
      }
      this.sharer.previous_id = id;
    },
    loadMore() {
      if (this.query.limit <= this.notes.Total) {
        this.query.limit += 10;
        this.callNoteList()
      }
    },
    addOffset(dateTimeString) {
      return DateTimeHelper.getMomentWithOffset(dateTimeString);
    },
    toContact(contact_id) {
      // Don't let clicking on own note's card_head
      if (this.contact.id === contact_id) {
        return;
      }
      this.$router.push({ name: 'promo-page-contacts-contact', params: { contact_id: contact_id } }).catch(() => {
      });
    },
    onToggle(id) {
      this.isCollapsed = !this.isCollapsed
      this.toggledId = id
    },
  }
};
</script>

<style scoped lang="scss">
.scroll-height {
  height: calc(100vh - 142px); // keeps it in viewport, hardcoded
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  flex-flow: row wrap;

  &.notes-list-scroller .wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 120px; // see AW-1631.
  }
}

.truncate-scroll-height {
  height: 140px;
}

.main {
  padding: 0 90px;
  margin-bottom: 0;
}

.card {
  width: 392px;
  min-height: 309px;
  background: #fff 0 0 no-repeat padding-box;
  box-shadow: $ew-box-shadow;
  border-radius: 10px;
  padding: 0 28px;
  position: relative;
  margin-right: 16px;
  margin-top: 16px;

  .card_head {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 28px 0 18px;
    cursor: pointer;

    &_my {
      cursor: default;
    }

    .avatar {
      img {
        width: 37px;
        height: 37px;
        border-radius: 50%;
      }
    }

    .title_block {
      margin-left: 36px;
      align-self: flex-start;

      .title {
        text-align: left;
        font-weight: 500;
        font-size: 15px;
        letter-spacing: 0;
        color: #000;
        opacity: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 274px;
      }

      .subtitle {
        text-align: left;
        font-size: 12px;
        letter-spacing: 0;
        color: #000;
      }
    }
  }

  .card_body {
    height: 154px;
    word-break: break-word;
    white-space: pre-line;

    .body_title {
      text-align: left;
      font-weight: normal;
      font-size: 14px;
      letter-spacing: 0;
    }

    .body_text {
      text-align: left;
      font-weight: 300;
      font-size: 14px;
      letter-spacing: 0;
    }

    .collapsed {
      max-height: 126px;
      overflow: hidden;
    }

    .collapse-action {
      font-weight: 300;
      font-size: 12px;
      letter-spacing: 0;
      color: $ew-blue;
      border: none;
      background-color: transparent;
    }

    .mu-input {
      width: 100%;
    }
  }

  .card_footer {
    height: 74px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    bottom: 0;

    .btn {
      margin-right: 20px;
      cursor: pointer;

      /* If event.target is inside SVG, like, PATH, it does not have parentNode
       * and biletumSharer/clickOutside fails to check
       * pointer-events: none makes &-share parent element the event target
       */
      &-share {
        pointer-events: none;
      }
    }

    .btn-share-wrap {
      display: inline-block;
      background-color: rgba(255, 255, 255, 0.001);
    }

    .save {
      color: $ew-blue;
    }

    .delete {
      color: $ew-red-lighter;
    }

    .switch-container {
      .flex-block {
        transform: translateY(-14px);
      }
    }

    .hint-block {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #b3b3b6;
      padding: 8px 0;
      min-height: 44px;
    }
  }

  /* Let's save some space in the note footer
   * by removing the rightmost button's margin
   */
  .card_footer .btn-group:last-child .btn:last-child {
    margin-right: 0;
  }

}

.nav-bar {
  z-index: 99;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  top: 73px;
  margin: 0 90px 15px;
}

.biletum-tabs {
  padding-left: 32px;

  &-promopage {
    border-radius: 10px;
    padding: 10px 34px 8px;
    background-color: #fff;
    box-shadow: 5px 5px 15px rgba(55, 84, 170, 0.1);
  }

  &-promopage-listsource {
    padding: 8px 34px 7px;
  }

  .biletum-tab {
    cursor: pointer;
    display: inline-block;
    margin-right: 2.9rem;
    color: #000;
    position: relative;
    transition: all 0.2s;
    font-weight: 600;
    font-size: 1.3rem;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      color: #00b6f8;
    }

    &::after {
      position: absolute;
      top: 100%;
      left: 0;
      height: 1px;
      background-color: transparent;
      transition: all 0.4s;
      width: 0.1%;
      content: "";
    }

    &-current {
      color: #00b6f8;

      &::after {
        background-color: #00b6f8;
        width: 100%;
      }
    }
  }
}

.create-btn-block {
  margin-left: 42px;

  .create-btn {
    text-align: center;
    border-width: 0;
    -webkit-appearance: none;
    color: #fff;
    cursor: pointer;

    &:hover,
    &:focus {
      outline: none;
    }
  }
}

.demo-circular-progress {
  margin: auto;
  display: block;
}

.main-skeleton {
  height: 100vh;
  display: flex;
}

.loading-block {
  position: relative;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 100px;
  pointer-events: none;
}

.inner-circular-progress {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mu-input-content {
  height: 34px;
}

.control {
  position: relative;
  height: 42px;

  .error-messages {
    margin-top: 0;
    padding-bottom: 0;

    .error-message {
      position: absolute;
      margin-top: -28px;
    }
  }

}
</style>
