


import { Component, Prop, Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';

@Component
export default class PromoSideBarRight extends Vue {

  @Prop({ type: String, default: '' })
  public readonly cardTitle: TranslateResult;

  @Prop({ type: Boolean, default: true })
  public readonly showCardActions: boolean;

  private onEditButtonClick(): void {
    this.$emit('editButtonPressed');
  }

  private onCardBodyClick(): void {
    this.$emit('cardBodyClicked');
  }

}
