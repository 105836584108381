


import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { TContact } from '@/_types/contact.type';
import { TUser } from '@/_types/user.type';
import { TEvent } from '@/_types/event.type';
import { TPromoPage } from '@/_types/promo-page/promo-page.type';
import EventHelper from '@/_helpers/event.helper';
import Avatar from '@/_components/avatar/avatar.vue';
import sideBarContactCard from '@/_modules/promo/components/side-bar-contact-card/side-bar-contact-card.vue';
import QrPopup from '@/_modules/promo/components/qr-popup/qr-popup.vue';
import IconAccessCode from '@/_modules/icons/components/sidebar/icon-access-code.vue';
import IconSettings from '@/_modules/icons/components/icon-settings.vue';
import IconArrowLeft from '@/_modules/icons/components/icon-arrow-left.vue';
import { AxiosResponse } from 'axios';
import SideBarRightUserSettings from '@/_modules/promo/components/side-bar-right-user-settings/side-bar-right-user-settings.vue';
import { TranslateResult } from 'vue-i18n';

const QR_CODE_URL_DOMAIN_PREFIX = 'https://' + window.location.host;

@Component({
  components: {
    Avatar,
    sideBarContactCard,
    QrPopup,
    IconAccessCode,
    IconSettings,
    IconArrowLeft,
    SideBarRightUserSettings,
  },
  computed: {
    ...mapGetters({
      user: '_userStore/user',
      event: '_eventStore/event',
      contactInfo: 'promoPageStore/contact',
      promoPage: 'promoPageStore/promoPage',
      isOpen: 'sideBarRightStore/isOpen',
      isUserSettingsOpen: 'sideBarRightStore/isUserSettingsOpen',
      isAuthenticated: 'authStore/isAuthenticated',
    }),
  },
})
export default class SideBarRightTabContact extends Vue {

  public isQRCodePopupVisible: boolean = false;
  public qrPopupTitle: TranslateResult = '';
  public qrPopupDescription: TranslateResult = '';
  public qrPopupContents: string = '';
  public exponentAccessCode: string = '';
  public exponentAccessCodeError: boolean = false;

  public readonly isOpen: boolean;
  public readonly user: TUser;
  public readonly event: TEvent;
  public readonly promoPage: TPromoPage;
  public readonly contactInfo: TContact;
  public readonly isAuthenticated: boolean;
  public readonly isUserSettingsOpen: boolean;

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  public get urlContactProfile(): string {
    if (!this.contactInfo) {
      return '';
    }

    return QR_CODE_URL_DOMAIN_PREFIX + this.$router.resolve({
      name: 'promo-page-contacts-contact',
      params: {
        eventId: this.eventId.toFixed(0),
        contact_id: this.contactInfo.id.toFixed(0)
      }
    }).href;
  }

  public get urlPromoPage(): string {
    if (!this.promoPage) {
      return '';
    }

    return QR_CODE_URL_DOMAIN_PREFIX + this.$router.resolve({
      name: 'promo-page-events-company',
      params: {
        eventId: this.eventId.toFixed(0),
        external_id: this.promoPage.external_id
      }
    }).href;
  }

  public get urlEvent(): string {
    return QR_CODE_URL_DOMAIN_PREFIX + this.$router.resolve({
      name: 'event-info',
      params: {
        eventId: this.eventId.toFixed(0),
      }
    }).href;
  }

  public get canEditCompany(): boolean {
    if (!this.promoPage || !this.contactInfo || !this.promoPage.attached_contacts) {
      return false;
    }

    const contact: any = this.promoPage.attached_contacts.find((item) => {
      return item.contact.id === this.contactInfo.id;
    });

    if (!contact) {
      return false;
    }

    return (contact.role === 'owner' || contact.role === 'assistant' || contact.role === 'admin');
  }

  public get isEventOrganizer(): boolean {
    return EventHelper.isContactEventOrganizer(this.event, this.contactInfo);
  }

  public get contactFullName(): string {
    if (!this.contactInfo) {
      return '';
    }
    let result = [this.contactInfo.name, this.contactInfo.surname].filter(x => x).join(' ');
    if (!result) {
      result = this.$t('contacts.info.noname') as string;
    }
    return result;
  }

  public get contactGeoInfo(): string {
    if (!this.contactInfo) {
      return '';
    }
    return [this.contactInfo.city, this.contactInfo.country].filter(x => x).join(', ');
  }

  public get promoPageGeoInfo(): string {
    if (!this.promoPage) {
      return '';
    }
    const cityName = this.promoPage.city ? this.promoPage.city.name : '';
    const countryName = this.promoPage.country ? this.promoPage.country.name : '';
    return [cityName, countryName].filter(x => x).join(', ');
  }

  public get promoPageDescription(): string {
    if (!this.promoPage || !this.promoPage.description) {
      return '';
    }
    const limit = 43;
    const isTruncated = this.promoPage.description.length > limit;
    return this.promoPage.description.substring(0, limit) + (isTruncated ? '…' : '');
  }

  public get eventGeoInfo(): string {
    if (!this.event) {
      return '';
    }
    const cityName = this.event.city ? this.event.city.name : '';
    const countryName = this.event.country ? this.event.country.name : '';
    return [cityName, countryName].filter(x => x).join(', ');
  }

  @Prop({ type: String, default: '' })
  public readonly tabContentTitle: TranslateResult;

  @Prop({ type: String, default: '' })
  public readonly contactInitials: string;

  @Watch('exponentAccessCode')
  private onExponentAccessCodeChange(): void {
    this.exponentAccessCodeError = false;
  }

  public beforeDestroy(): void {
    this.hideQRPopup();
  }

  private openEditContact(): void {
    if (this.$route.name !== 'promo-page-edit-contact') {
      this.$router.push({name: 'promo-page-edit-contact'});
    }
  }

  private openEditCompany(): void {
    if (this.$route.name !== 'promo-page-edit-company') {
      this.$router.push({name: 'promo-page-edit-company'});
    }
  }

  private openOrganizerCabinet(): void {
    if (this.$route.name !== 'promo-page-cabinet-event-settings') {
      this.$router.push({name: 'promo-page-cabinet-event-settings'});
    }
  }

  private async logout(): Promise<void> {
    await this.$store.dispatch('authStore/authLogout');
    await this.$store.dispatch('_eventStore/reset');
    await this.$router.push({name: 'event-info', params: {eventId: this.eventId.toFixed(0)}});
  }

  private showQRPopup(): void {
    this.isQRCodePopupVisible = true;
    // this.$store.dispatch('sideBarRightStore/setIsQRCodePopupVisible', true);
  }

  private hideQRPopup(): void {
    this.isQRCodePopupVisible = false;
    // this.$store.dispatch('sideBarRightStore/setIsQRCodePopupVisible', false);
  }

  private setQRPopupContents(title: TranslateResult = '', descr: TranslateResult = '', strToEncode: string = ''): void {
    if (!strToEncode) {
      return;
    }
    this.qrPopupTitle = title;
    this.qrPopupDescription = descr;
    this.qrPopupContents = strToEncode;
  }

  private showQrContact(): void {
    this.hideQRPopup();
    this.showQRPopup();
    this.setQRPopupContents(this.$t('sideBarTabs.contact.cards.contact.qrTitle'), this.$t('sideBarTabs.contact.cards.contact.qrDescr'), this.urlContactProfile);
  }

  private showQrCompany(): void {
    this.hideQRPopup();
    this.showQRPopup();
    this.setQRPopupContents(this.$t('sideBarTabs.contact.cards.company.qrTitle'), this.$t('sideBarTabs.contact.cards.company.qrDescr'), this.urlPromoPage);
  }

  private showQrEvent(): void {
    this.hideQRPopup();
    this.showQRPopup();
    this.setQRPopupContents(this.$t('sideBarTabs.contact.cards.organizer.qrTitle'), this.$t('sideBarTabs.contact.cards.organizer.qrDescr'), this.urlEvent);
  }

  private async activateExhibitorCode(): Promise<void> {
    this.exponentAccessCodeError = false;
    if (!this.exponentAccessCode) {
      return;
    }
    // TODO: use new store when it is written, refactor this method correspondingly
    const result: AxiosResponse = await this.$store.dispatch('eventStore/activateCode', {
      event_id: this.eventId,
      code: this.exponentAccessCode
    });
    // TODO: remove error handling. Team lead said that for PATCH requests we should just make request and forget about it
    if (!result || !(result.status === 202 || result.status === 200)) {
      this.exponentAccessCodeError = true;
    } else {
      await this.$store.dispatch('promoPageStore/getPromoPage', this.eventId);
    }
  }

  private proceedToUserSettings(): void {
    this.$store.dispatch('sideBarRightStore/setIsUserSettingsOpen', true);
  }

  private closeUserSettings(): void {
    this.$store.dispatch('sideBarRightStore/setIsUserSettingsOpen', false);
  }

}
